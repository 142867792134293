import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ProjectsGrid, Layout, Image, Button } from 'components';
import { OneCol } from 'slices';
import './projects.scss';

const Projects = () => {
  const data = useStaticQuery(graphql`
    query projectsQuery {
      prismicSettings {
        data {
          hero_image {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          projects_cta_link {
            url
          }
          projects_cta_text {
            text
            html
          }
          projects_description {
            text
            html
          }
          projects_title {
            html
            text
          }
        }
      }
      allPrismicProject {
        nodes {
          data {
            hero_image {
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            project_title {
              text
            }
          }
          uid
        }
      }
    }
  `);

  const { prismicSettings, allPrismicProject } = data;
  const { nodes: projects } = allPrismicProject;
  const { data: settingsData } = prismicSettings;
  const {
    hero_image: heroImage,
    projects_cta_link: ctaLink,
    projects_cta_text: ctaText,
    projects_description: content,
    projects_title: title,
  } = settingsData;

  return (
    <Layout>
      <section className="projects-page">
        <OneCol data={{ primary: { title, content, cta_text: ctaText, cta_link: ctaLink, image: heroImage } }} />
        <ProjectsGrid projects={projects} />
      </section>
    </Layout>
  );
};

export default Projects;
